<template>
  <div class="api">
      <div class="api-top">
          <h2>API对接为您提供更为高效的服务！</h2>
      </div>
        <div class="api-nav">
            <div class="api-nav-tit">
                <h2>开放能力</h2>
                <p>稳定 . 易用 . 高效</p>
            </div>
            <div class="api-nav-main">
                <el-row :gutter="12">
                    <el-col :span="8">
                        <el-card>
                            <img src="../../assets/api/kf1.png" class="image" alt="">
                           <p>实时查询</p> 
                        </el-card>
                    </el-col>
                    <el-col :span="8">
                        <el-card>
                            <img src="../../assets/api/kf2.png" class="image" alt="">
                           <p>内容丰富</p> 
                        </el-card>
                    </el-col>
                    <el-col :span="8">
                        <el-card>
                            <img src="../../assets/api/kf3.png" class="image" alt="">
                           <p>接口响应高效</p> 
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="api-nav">
            <div class="api-nav-tit">
                <h2>接入方式</h2>
                <p>专业的技术指导，对接更加简单、便捷</p>
            </div>
            <div class="api-nav-main">
                <ul class="step">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <li>
                                <div class="icon">
                                    <img src="../../assets/api/step1.png" alt="">
                                </div>
                                <p>①注册企业账号</p>
                            </li>
                        </el-col>
                        <el-col :span="6">
                            <li>
                                <div class="icon">
                                    <img src="../../assets/api/step2.png" alt="">
                                </div>
                                <p>②充值开通对应服务</p>
                            </li>
                        </el-col>
                        <el-col :span="6">
                            <li>
                                <div class="icon">
                                    <img src="../../assets/api/step3.png" alt="">
                                </div>
                                <p>③开发和调试</p>
                            </li>
                        </el-col>
                        <el-col :span="6">
                            <li>
                                <div class="icon">
                                    <img src="../../assets/api/step4.png" alt="">
                                </div>
                                <p>④开始使用</p>
                            </li>
                        </el-col>
                    </el-row>
                </ul>
            </div>
        </div>
        <div class="api-nav">
            <div class="api-nav-tit">
                <h2>我们的优势</h2>
                <p>行业领先的技术水平，一流的服务能力</p>
            </div>
            <div class="api-nav-main1">
                 <el-row :gutter="12">
                    <el-col :span="12">
                        <div class="img-left">
                            <img src="../../assets/api/ys.png" alt="">
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="img-right">
                            <h3>成熟稳定的服务和技术</h3> 
                            <p>与多家大型公司合作</p>
                            <h3>快捷方便的对接流程</h3>
                            <p>API对接，调试、开发过程更加高效</p>
                            <h3>不断的升级与更新服务</h3>
                            <p>不断的完善已有的服务和扩展更多的产品</p>
                        </div>
                    </el-col>
                 </el-row>
                <!-- <el-row :gutter="12">
                    <el-col :span="8">
                        <el-card shadow="hover">
                            <h3>成熟稳定的服务和技术</h3> 
                            <p>与多家大型公司合作</p>
                        </el-card>
                    </el-col>
                    <el-col :span="8">
                        <el-card shadow="hover">
                            <h3>快捷方便的对接流程</h3>
                            <p>API对接，调试、开发过程更加高效</p>
                        </el-card>
                    </el-col>
                    <el-col :span="8">
                        <el-card shadow="hover">
                            <h3>不断的升级与更新服务</h3>
                            <p>不断的完善已有的服务和扩展更多的产品</p>
                        </el-card>
                    </el-col>
                </el-row> -->
            </div>
        </div>
        <div class="api-nav">
            <div class="api-nav-tit">
                <h2>合作客户</h2>
                <p>合作客户，携手共赢</p>
            </div>
            <div class="api-nav-main">
                <ul class="partner">
                    <li v-for="(item,index) in 10" :key="index">
                        <img src="../../assets/partner/fanyuan.png" alt="">
                    </li>
                </ul>
            </div>
        </div>
        <div class="api-nav">
            <div class="api-nav-tit">
                <h2>商务咨询</h2>
            </div>
            <div class="api-nav-main">
                <div class="section">
                    TEL:021-69956067
                </div>
            </div>
        </div>
  </div>
</template>

<script>
    export default {
        data(){
            return {
                
            }
        },
    }
</script>

<style scoped>
   @import './Api.css';
</style>